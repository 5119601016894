import { graphql, useStaticQuery } from 'gatsby';
import {
    ResourceDocumentsQuery,
    ResourceDocumentsQuery_allSanityResourceDocument_nodes as ResourceDocument,
} from '../__generated__/ResourceDocumentsQuery';

export { ResourceDocument };

export default function useAllResources(): ResourceDocument[] {
    const query: ResourceDocumentsQuery = useStaticQuery(graphql`
        query ResourceDocumentsQuery {
            allSanityResourceDocument {
                nodes {
                    _id
                    title
                    link
                    content: _rawContent(resolveReferences: { maxDepth: 2 })
                    description
                    sections {
                        _id
                    }
                    groups {
                        _id
                    }
                }
            }
        }
    `);

    return query.allSanityResourceDocument.nodes;
}
