import { graphql, useStaticQuery } from 'gatsby';
import {
    ResourceSectionsQuery,
    ResourceSectionsQuery_allSanityResourceSectionDocument_nodes as ResourceSection,
} from '../__generated__/ResourceSectionsQuery';

export { ResourceSection };

export default function useAllResourceSections(): ResourceSection[] {
    const query: ResourceSectionsQuery = useStaticQuery(graphql`
        query ResourceSectionsQuery {
            allSanityResourceSectionDocument {
                nodes {
                    _id
                    title
                    order
                }
            }
        }
    `);

    return query.allSanityResourceSectionDocument.nodes;
}
