import React from 'react';
import styled from 'styled-components';
import { DarkTheme } from '../components/Theme';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Intro from '../components/Intro';
import Metadata from '../components/Metadata';
import ResourceTables from '../components/ResourceTables';
import Footer from '../components/Footer';
import useResourcesPage from '../utils/useResourcesPageQuery';

const Section = styled.div`
    display: grid;
    grid-template-columns: 100%;

    &[data-section='banner'] {
        grid-row-gap: var(--sectionSpacing10);
        padding-bottom: var(--sectionSpacing11);
    }
`;

export default function Resources(): React.ReactElement {
    const { banner, intro, pageMetadata } = useResourcesPage();

    return (
        <React.Fragment>
            {pageMetadata && <Metadata pageMetadata={pageMetadata} />}
            <DarkTheme>
                <Navbar />
                <Section data-section="banner">
                    {banner && <Banner {...banner} />}
                    {intro && <Intro contentRaw={intro} />}
                    <ResourceTables />
                </Section>
                <Footer />
            </DarkTheme>
        </React.Fragment>
    );
}
