import React from 'react';
import styled from 'styled-components';
import { ResourceDocument } from '../utils/useAllResourceDocuments';
import BlockContent from '@sanity/block-content-to-react';
import { sanity } from '../../config.json';
const { projectId, dataset } = sanity.client;
import { Viewport } from '../Settings';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size2);
`;

const Title = styled.h4`
    font-size: var(--size4);
    line-height: 1.5;
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const A = styled.a`
    font-size: var(--size3);
    line-height: 1.5;
    color: var(--foregroundColor);
    text-decoration: underline;
    word-wrap: break-word;

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size2);
    }
`;

const Description = styled.p`
    font-size: var(--size3);
    line-height: 1.5;
    color: var(--contentColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size2);
    }
`;

const ReadMore = styled.button`
    font-size: var(--size3);
    line-height: 1.5;
    text-decoration: underline;
    color: var(--contentColor);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size2);
    }
`;

const Content = styled(BlockContent)`
    font-size: var(--size3);
    line-height: 1.5;
    margin-top: var(--size2);

    * {
        color: var(--contentColor);
        transition: color 600ms ease;
        margin-top: var(--size1);

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        list-style-type: disc;
        padding-left: 1em;
    }

    ol {
        list-style-type: decimal;
        padding-left: 1em;
    }

    li {
        //
    }

    p {
        margin-top: 1em;
    }

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size2);
    }
`;

interface Asset {
    url: string;
    originalFilename: string;
}

function getAsset(props: any): Asset | void {
    if (
        typeof props === 'object' &&
        typeof props.node === 'object' &&
        typeof props.node.asset === 'object' &&
        typeof props.node.asset.originalFilename === 'string' &&
        typeof props.node.asset.url === 'string'
    ) {
        return props.node.asset;
    }
}

function Resource({
    resourceDocument,
}: {
    resourceDocument: ResourceDocument;
}): React.ReactElement {
    const [readMore, setShowMore] = React.useState<boolean>(false);
    const toggleReadMore = React.useCallback(
        (): void => setShowMore((state): boolean => !state),
        []
    );

    const { _id: id, title, link, description, content } = resourceDocument;

    return (
        <Container key={String(id)}>
            <Title>{title}</Title>

            {link && <A href={link}>{link}</A>}

            {description && <Description>{description}</Description>}

            {content && (
                <React.Fragment>
                    {readMore && (
                        <Content
                            blocks={content}
                            renderContainerOnSingleChild
                            projectId={projectId}
                            dataset={dataset}
                            serializers={{
                                types: {
                                    file(
                                        props: unknown
                                    ): React.ReactElement | null {
                                        const asset = getAsset(props);
                                        if (asset) {
                                            return (
                                                <p>
                                                    <A href={asset.url}>
                                                        {asset.originalFilename}
                                                    </A>
                                                </p>
                                            );
                                        }
                                        return null;
                                    },
                                },
                            }}
                        />
                    )}
                    <div>
                        <ReadMore onClick={toggleReadMore}>
                            {readMore ? 'Show less' : 'Show more'}
                        </ReadMore>
                    </div>
                </React.Fragment>
            )}
        </Container>
    );
}

export default React.memo(Resource);
