import React from 'react';
import styled from 'styled-components';
import ResourceTable from './ResourceTable';
import TableOfContents from './TableOfContents';
import useAllResourceSections, {
    ResourceSection,
} from '../utils/useAllResourceSections';
import useResourceDocuments, {
    ResourceDocument,
} from '../utils/useAllResourceDocuments';
import notNull from '../utils/notNull';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--sectionSpacing11);
`;

function resourceBelongsToSection(
    resourceDocument: ResourceDocument,
    resourceSection: ResourceSection
): boolean {
    return resourceDocument.sections
        ? resourceDocument.sections
              .filter(notNull)
              .some(({ _id }): boolean => _id === resourceSection._id)
        : false;
}

function ResourceTables(): React.ReactElement {
    const resourceDocuments = useResourceDocuments().sort((a, b): number =>
        String(a.title).localeCompare(String(b.title))
    );
    const resourceSections = useAllResourceSections()
        // Filter away sections without any documents
        .filter((resourceSection): boolean =>
            resourceDocuments.some((resourceDocument): boolean =>
                resourceBelongsToSection(resourceDocument, resourceSection)
            )
        )
        .sort((a, b): number => (a.order || 0) - (b.order || 0));

    return (
        <Container>
            <TableOfContents resourceSections={resourceSections} />
            {resourceSections.map(
                (resourceSection): React.ReactElement => {
                    const sectionDocuments = resourceDocuments.filter(
                        (resourceDocument): boolean =>
                            resourceBelongsToSection(
                                resourceDocument,
                                resourceSection
                            )
                    );

                    return (
                        <ResourceTable
                            key={String(resourceSection._id)}
                            resourceSection={resourceSection}
                            resourceDocuments={sectionDocuments}
                        />
                    );
                }
            )}
        </Container>
    );
}

export default React.memo(ResourceTables);
