import { graphql, useStaticQuery } from 'gatsby';
import {
    ResourcesPageQuery,
    ResourcesPageQuery_sanityResourcesPage as ResourcesPage,
} from '../__generated__/ResourcesPageQuery';

export default function useResourcesPageQuery(): ResourcesPage {
    const query: ResourcesPageQuery = useStaticQuery(graphql`
        query ResourcesPageQuery {
            sanityResourcesPage(_id: { eq: "resourcesPage" }) {
                pageMetadata {
                    ...PageMetadataFragment
                }
                banner {
                    ...BannerFragment
                }
                intro: _rawIntro
            }
        }
    `);

    if (query && query.sanityResourcesPage) {
        return query.sanityResourcesPage;
    }

    throw new Error('Unfound ResourcesPage');
}
