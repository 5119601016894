import React from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { ResourceSection } from '../utils/useAllResourceSections';
import { Viewport } from '../Settings';

const Container = styled.ol`
    color: var(--foregroundColor);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size4);
    padding: 0 calc(100% / 24 * 3);

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 calc(var(--sizeGutters) * 2);
        grid-template-columns: 100%;
    }
`;

const Title = styled.h2`
    font-size: var(--size6);

    @media (max-width: ${Viewport.Mobile}px) {
        font-size: var(--size3);
    }
`;

const Inner = styled.ul`
    list-style-type: lower-roman;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size2);
    font-size: var(--size5);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size3);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        list-style-type: disc;
    }
`;

const Item = styled.li`
    // display: block;
`;

const Link = styled.a`
    color: inherit;
`;

function TableOfContents({
    resourceSections,
}: {
    resourceSections: ResourceSection[];
}): React.ReactElement {
    return (
        <Container>
            <Title>Table of Contents</Title>
            <Inner>
                {resourceSections.map(
                    (resourceSection): React.ReactElement | null =>
                        resourceSection.title ? (
                            <Item key={String(resourceSection._id)}>
                                <Link
                                    href={`#${slugify(resourceSection.title)}`}
                                >
                                    {resourceSection.title}
                                </Link>
                            </Item>
                        ) : null
                )}
            </Inner>
        </Container>
    );
}

export default React.memo(TableOfContents);
