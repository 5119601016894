import React from 'react';
import slugify from 'slugify';
import styled from 'styled-components';
import { ResourceDocument } from '../utils/useAllResourceDocuments';
import { ResourceSection } from '../utils/useAllResourceSections';
import Resource from './Resource';
import { Viewport } from '../Settings';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size6);

    @media (max-width: ${Viewport.Mobile}px) {
        grid-row-gap: var(--sizeGutters);
    }
`;

const Heading = styled.div`
    padding: 0 calc(100% / 24 * 3);
    font-size: var(--size6);
    line-height: 1.38;
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.TabletPortrait}px) {
        font-size: var(--size5);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 var(--sizeGutters);
        font-size: var(--size4);
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
`;

const Item = styled.div`
    padding: var(--size5) calc(100% / 24 * 9) var(--size5) calc(100% / 24 * 3);
    --contentColor: var(--foregroundColorMix3);
    --border: 1px solid var(--foregroundColorMix5);
    background-color: var(--backgroundColor);
    transition: background-color 600ms ease;
    border-top: var(--border);
    &:last-child {
        border-bottom: var(--border);
    }

    [data-whatinput='mouse'] &:hover,
    [data-whatinput='touch'] &:hover,
    &:focus-within {
        background-color: var(--highlightColor);
        --contentColor: var(--foregroundColor);
    }

    @media (max-width: ${Viewport.TabletLandscape}px) {
        padding: var(--size5) calc(100% / 24 * 6) var(--size5)
            calc(100% / 24 * 3);
    }

    @media (max-width: ${Viewport.TabletPortrait}px) {
        padding: var(--size5) calc(100% / 24 * 3) var(--size5)
            calc(100% / 24 * 3);
    }

    @media (max-width: ${Viewport.Mobile}px) {
        padding: var(--sizeGutters);
    }
`;

function ResourceTable({
    resourceSection,
    resourceDocuments,
}: {
    resourceSection: ResourceSection;
    resourceDocuments: ResourceDocument[];
}): React.ReactElement | null {
    return resourceDocuments.length > 0 ? (
        <Container>
            {resourceSection.title && (
                <Heading id={slugify(resourceSection.title)}>
                    {resourceSection.title}
                </Heading>
            )}
            <Inner>
                {resourceDocuments.map(
                    (resourceDocument): React.ReactElement => (
                        <Item key={String(resourceDocument._id)}>
                            <Resource resourceDocument={resourceDocument} />
                        </Item>
                    )
                )}
            </Inner>
        </Container>
    ) : null;
}

export default React.memo(ResourceTable);
